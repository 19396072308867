import React from 'react';
import {Divider, Grid, Checkbox, Icon} from 'semantic-ui-react';
import {useSelector} from "react-redux";
import DropdownMoySklad from "../../formControls/DropdownMoySklad";


const markMissingProductAs = [
    {
        id: 'none',
        name: 'Оставить без изменения'
    },
    {
        id: 'not_available',
        name: 'Нет в наличии'
    },
    {
        id: 'not_on_display',
        name: 'Скрытые'
    },
    {
        id: 'deleted',
        name: 'Удаленные'
    },
]

const unloadGoodsType = [
    {
        id: 'not_checked',
        name: 'Выгружать все, кроме отмеченных товаров'
    },
    {
        id: 'checked',
        name: 'Выгружать только отмеченный товар'
    },
    {
        id: 'all',
        name: 'Выгружать все'
    },
]

const ExportProduct = ({
                           visibleLink = true,
                           productAttributes,
                           stateExportProducts,
                           setStateExportProductsData = () => {},
                           clickOnlyFile = () => {}
                       }) => {
    const theme = useSelector(state => state.app.theme);
    return (
        <Grid textAlign='left' style={{marginTop: '5px'}} verticalAlign='middle'>
            {/*<Grid.Row>*/}
            {/*    <Grid.Column width={3} className='grid-column-1'>*/}
            {/*        <strong>Обновить принудительно</strong>*/}
            {/*    </Grid.Column>*/}

            {/*    <Grid.Column width={3} className='grid-column-2'>*/}
            {/*        <Checkbox*/}
            {/*            className={theme}*/}
            {/*            toggle*/}
            {/*            checked={Boolean(stateExportProducts.force_update)}*/}
            {/*            onChange={() => setStateExportProductsData('force_update', !Boolean(stateExportProducts.force_update))}*/}
            {/*        > </Checkbox>*/}
            {/*    </Grid.Column>*/}
            {/*</Grid.Row>*/}

            {/*<Divider/>*/}

            {/*<Grid.Row>*/}
            {/*    <Grid.Column className='grid-column-1' width={3}>*/}
            {/*        <strong>Загрузить позиции в наличии</strong>*/}
            {/*    </Grid.Column>*/}

            {/*    <Grid.Column className='grid-column-2' width={3}>*/}
            {/*        <Checkbox className={theme}*/}
            {/*                  toggle*/}
            {/*                  checked={Boolean(stateExportProducts.only_available)}*/}
            {/*                  onChange={() => setStateExportProductsData('only_available', !Boolean(stateExportProducts.only_available))}*/}
            {/*        />*/}
            {/*    </Grid.Column>*/}
            {/*</Grid.Row>*/}

            {/*<Divider/>*/}

            <Grid.Row>
                <Grid.Column width={3} className='grid-column-1'>
                    <strong>Помечать отсутствующие продукты следующим статусом</strong>
                </Grid.Column>

                <Grid.Column width={3} className='grid-column-2'>
                    <DropdownMoySklad
                        fields={markMissingProductAs}
                        notChosen={false}
                        activeOptions={stateExportProducts.mark_missing_product_as}
                        onOptionsChange={value => {
                            setStateExportProductsData('mark_missing_product_as', value)
                        }}
                    ></DropdownMoySklad>
                </Grid.Column>
            </Grid.Row>

            <Divider/>

            <Grid.Row>
                <Grid.Column width={3} className='grid-column-1'>
                    <strong>Использовать модификации товаров как разновидности</strong>
                    <p className="sub-text">Функция синхронизирует модификации товаров из МойСклад с разновидностями в Сату. Можно синхронизировать только до трёх модификаций. Важно, что главный товар из МойСклад не переносится, только его модификации.</p>
                </Grid.Column>

                <Grid.Column width={3} className='grid-column-2'>
                    <Checkbox className={theme}
                              toggle
                             checked={Boolean(stateExportProducts['mods_as_variety'])}
                             onChange={() => setStateExportProductsData('mods_as_variety', !Boolean(stateExportProducts['mods_as_variety']))}
                    > </Checkbox>
                </Grid.Column>
            </Grid.Row>

            <Divider/>

            <Grid.Row>
                <Grid.Column width={3} className='grid-column-1'>
                    <strong>Поле выгрузки товара</strong>
                </Grid.Column>

                <Grid.Column width={3} className='grid-column-2'>


                    <DropdownMoySklad
                        fields={productAttributes}
                        type={['boolean']}
                        notChosen={false}
                        activeOptions={stateExportProducts.unload_goods_field}
                        onOptionsChange={value => {
                            setStateExportProductsData('unload_goods_field', value)
                        }}
                    />
                    <p className="sub-text">Тип поля флажок</p>
                </Grid.Column>
            </Grid.Row>

            <Divider/>

            <Grid.Row>
                <Grid.Column width={3} className='grid-column-1'>
                    <strong>Тип выгрузки товара</strong>
                </Grid.Column>
                <Grid.Column width={3} className='grid-column-2'>
                    <DropdownMoySklad
                        fields={unloadGoodsType}
                        notChosen={false}
                        activeOptions={stateExportProducts.unload_goods_type}
                        onOptionsChange={value => {
                            setStateExportProductsData('unload_goods_type', value)
                        }}
                    />
                </Grid.Column>
            </Grid.Row>

            <Divider/>

            <Grid.Row>
                <Grid.Column width={3} className='grid-column-1'>
                    <strong>Ссылка на xlsx файл</strong>
                </Grid.Column>
                <Grid.Column width={4} className='grid-column-2' style={{minWidth: '500px'}}>
                    <a href={stateExportProducts.link_XLSX_File}
                       className={visibleLink ? 'link' : 'link-disabled'}>{stateExportProducts.link_XLSX_File}</a>
                </Grid.Column>
                <Grid.Column width={1}  >
                    {visibleLink && (
                        <Icon className='icon-click' name='sync' onClick={clickOnlyFile} />
                    )}

                </Grid.Column>



            </Grid.Row>

            <Divider/>

        </Grid>
    );
};

export default ExportProduct;
