import { configureStore } from '@reduxjs/toolkit';
import appReducer from "./appReducer";
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import { createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';


// Создаем трансформер, который будет обрабатывать состояние перед его сохранением
const AppTransform = createTransform(
    // Преобразовываем исходящее состояние
    (inboundState, key) => {
        // Проверяем, что это состояние appReducer
        if (key === 'app') {
            // Создаем копию состояния без accountId
            const { accountId, activeSettingsId, ...rest } = inboundState;
            return rest;
        }
        return inboundState;
    },
    // Входящее состояние (при восстановлении) не изменяем
    null,
    // Указываем, что этот трансформер должен работать только с appReducer
    { whitelist: ['app'] }
);

const persistConfig = {
    key: 'root',
    storage,
    transforms: [AppTransform],
};

const rootReducer = combineReducers({
    app: appReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

const persistor = persistStore(store);

export { store, persistor };