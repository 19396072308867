import React, {Fragment, useEffect, useState} from 'react';
import {Divider, Grid, Checkbox} from 'semantic-ui-react';
import DropdownMoySklad from "../../formControls/DropdownMoySklad";
import {useSelector} from "react-redux";

const OrdersSync = ({
                        stateOrderArray,
                        stateOrder,
                        setStateOrderData = (key, value) => {},
                        visibleOrderSyncItems,
                    }) => {

    const theme = useSelector(state => state.app.theme);
    const [counterpartyLoading, setCounterpartyLoading] = useState(true);
    const isVisible = (item) => visibleOrderSyncItems?.[item] !== undefined ? visibleOrderSyncItems[item] : true;
    useEffect(() => {
        if (stateOrderArray.counterpartyArray.length > 0)
            setCounterpartyLoading(false);
    }, [stateOrderArray.counterpartyArray])

    // Массив объектов для хранения информации о каждой секции
    const sections = [
        {
            title: 'Организация',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.organizationsArray}
                activeOptions={stateOrder.organization}
                notChosen={false}
                onOptionsChange={(value) => {
                    setStateOrderData('organization', value)
                }}
            />,
            visibility: true,
        },
        {
            title: 'Создание контрагента',
            subComponent: (
                <>
                    <Checkbox className={theme}
                        toggle
                        checked={stateOrder.autoCounterparty}
                        onChange={() => setStateOrderData('autoCounterparty', !stateOrder.autoCounterparty)}
                    />
                    {!stateOrder.autoCounterparty && (
                        <DropdownMoySklad
                            loading={counterpartyLoading}
                            fields={stateOrderArray.counterpartyArray}
                            notChosen={false}
                            activeOptions={stateOrder.counterparty}
                            onOptionsChange={(value) => {
                                setStateOrderData('counterparty', value)
                            }}/>
                    )}
                </>
            ),
            visibility: true,
        },
        {
            title: 'Синхронизация товаров',
            hint: 'Укажите по каким признакам выполнять поиск товаров при создании заказов.',
            p_up1: 'МойСклад',
            p_up2: 'Satu.kz',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.typeArticle}
                notChosen={false}
                fields={[
                    {
                        id: 'code',
                        name: 'Код товара (рекомендовано)'
                    },
                    {
                        id: 'article',
                        name: 'Артикул товара'
                    },
                    {
                        id: 'externalCode',
                        name: 'Внешний код товара'
                    }
                ]}
                onOptionsChange={(value) => {
                    setStateOrderData('typeArticle', value)
                }}/>,
            subComponent2: <DropdownMoySklad
                activeOptions={stateOrder.type_article_satu}
                notChosen={false}
                fields={[
                    {
                        id: 'external_id',
                        name: 'Внешний код'
                    },
                    {
                        id: 'sku',
                        name: 'Артикул/код'
                    },
                ]}
                onOptionsChange={(value) => {
                    setStateOrderData('type_article_satu', value)
                }}/>,
            visibility: true
        },
        {
            title: 'Тип цены',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.priceType}
                notChosen={false}
                fields={stateOrderArray.priceTypeArray}
                onOptionsChange={(value) => {
                    setStateOrderData('priceType', value)
                }}/>,
            visibility: true
        },
        {
            title: 'Склад',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.storeArray}
                activeOptions={stateOrder.store}
                notChosen={false}
                onOptionsChange={(value) => {
                    setStateOrderData('store', value)
                }}/>,
            visibility: true
        },
        {
            title: 'Резервирование товаров',
            subComponent: <Checkbox className={theme}
                toggle
                                    checked={stateOrder.reservationGoods}
                                    onChange={() => setStateOrderData('reservationGoods', !stateOrder.reservationGoods)}/>,
            visibility: isVisible('visibleReservationGoods')
        },
        {
            title: 'Канал продаж',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.saleschannelArray}
                activeOptions={stateOrder.saleschannel}
                onOptionsChange={(value) => {
                    setStateOrderData('saleschannel', value)
                }}/>,
            visibility: isVisible('visibleSaleschannel')
        },
        {
            title: 'Нумерация Satu',
            subComponent: (
                <>
                    <Checkbox className={theme}
                        toggle
                        checked={stateOrder.autoOrdersNumbering}
                        onChange={() => setStateOrderData('autoOrdersNumbering', !stateOrder.autoOrdersNumbering)}
                    />
                    {!stateOrder.autoOrdersNumbering && (
                        <>
                            <DropdownMoySklad
                                fields={stateOrderArray.customerOrderAttributes}
                                activeOptions={stateOrder.ordersNumbering}
                                notChosen={false}
                                type={['string', 'text']}
                                onOptionsChange={(value) => {
                                    setStateOrderData('ordersNumbering', value)
                                }}
                            />
                            <p className="sub-text">Тип поля - строка или текст</p>
                        </>
                    )}

                </>
            ),
            visibility: isVisible('visibleOrdersNumbering')
        },

        {
            title: 'Валюта',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.currencyArray}
                activeOptions={stateOrder.currency}
                onOptionsChange={(value) => {
                    setStateOrderData('currency', value)
                }}/>,
            visibility: isVisible('visibleCurrency')
        },

        {
            title: 'Проект',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.projectArray}
                activeOptions={stateOrder.project}
                onOptionsChange={(value) => {
                    setStateOrderData('project', value)
                }}/>,
            visibility: isVisible('visibleProject')
        },
        {
            title: 'Сотрудник',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.employeeArray}
                activeOptions={stateOrder.employee}
                onOptionsChange={(value) => {
                    setStateOrderData('employee', value)
                }}/>,
            visibility: isVisible('visibleEmployee')
        },
        {
            title: 'Отдел',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.groupArray}
                activeOptions={stateOrder.group}
                onOptionsChange={(value) => {
                    setStateOrderData('group', value)
                }}/>,
            visibility: isVisible('visibleGroup')
        },
        {
            title: 'Рейтинг клиента',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.customerOrderAttributes}
                activeOptions={stateOrder.clientAvgRating}
                type={['long']}
                onOptionsChange={(value) => {
                    setStateOrderData('clientAvgRating', value)
                }}/>,

            subText: 'Тип поля - целое число',
            visibility: isVisible('visibleClientAvgRating')
        },
        {
            title: 'Комментарий пользователя',
            subComponent: <DropdownMoySklad
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                activeOptions={stateOrder.clientNotes}
                onOptionsChange={(value) => {
                    setStateOrderData('clientNotes', value)
                }}
            />,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visibleClientNotes')
        },
        {
            title: 'Название способа доставки',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.deliveryOptionName}
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                onOptionsChange={(value) => {
                    setStateOrderData('deliveryOptionName', value)
                }}/>,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visibleDeliveryOptionName')
        },
        {
            title: 'Стоимость доставки',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.deliveryOptionShippingService}
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                onOptionsChange={(value) => {
                    setStateOrderData('deliveryOptionShippingService', value)
                }}/>,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visibleDeliveryOptionShippingService')
        },
        {
            title: 'Название способа оплаты',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.paymentOptionName}
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                onOptionsChange={(value) => {
                    setStateOrderData('paymentOptionName', value)
                }}/>,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visiblePaymentOptionName')
        },
        {
            title: 'Источник заказа',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.source}
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                onOptionsChange={(value) => {
                    setStateOrderData('source', value)
                }}/>,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visibleSource')
        },
        {
            title: 'Комиссия за заказ',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.cpaCommissionAmount}
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                onOptionsChange={(value) => {
                    setStateOrderData('cpaCommissionAmount', value)
                }}/>,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visibleCpaCommissionAmount')
        },
        {
            title: 'Клиент просит не звонить',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.dontCallCustomerBack}
                fields={stateOrderArray.customerOrderAttributes}
                type={['boolean']}
                onOptionsChange={(value) => {
                    setStateOrderData('dontCallCustomerBack', value)
                }}/>,
            subText: 'Тип поля - флажок',
            visibility: isVisible('visibleDontCallCustomerBack')
        },
        {
            title: 'Данные об акциях по доставке',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.psPromotionName}
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                onOptionsChange={(value) => {
                    setStateOrderData('psPromotionName', value)
                }}/>,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visiblePsPromotionName')
        },
        {
            title: 'Заказ с бесплатной доставкой',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.hasOrderPromoFreeDelivery}
                fields={stateOrderArray.customerOrderAttributes}
                type={['boolean']}
                onOptionsChange={(value) => {
                    setStateOrderData('hasOrderPromoFreeDelivery', value)
                }}/>,
            subText: 'Тип поля - флажок',
            visibility: isVisible('visibleHasOrderPromoFreeDelivery')
        },
        {
            title: 'Способ оплаты',
            subComponent: <DropdownMoySklad
                activeOptions={stateOrder.paymentDataStatus}
                fields={stateOrderArray.customerOrderAttributes}
                type={['string', 'text']}
                onOptionsChange={(value) => {
                    setStateOrderData('paymentDataStatus', value)
                }}/>,
            subText: 'Тип поля - строка или текст',
            visibility: isVisible('visiblePaymentDataStatus')
        },
    ];

    return (
        <div style={{textAlign: 'left'}}>
            <Grid style={{marginTop: '5px'}} verticalAlign="middle">
                {sections.map((section, index) => (
                    section.visibility && (
                        <Fragment key={index}>
                            <Grid.Row>
                                <Grid.Column  width={3} className='grid-column-1'>
                                    <strong>
                                        {section.title}
                                    </strong>
                                    {section.hint &&
                                        <p className='over-text'>{section.hint}</p>
                                    }
                                </Grid.Column>
                                <Grid.Column textAlign="left"  width={3}
                                             className='grid-column-2'>
                                    {section.p_up1 && <p className="sub-text">{section.p_up1}</p>}
                                    {section.subComponent}
                                    {section.subText && <p className="sub-text">{section.subText}</p>}
                                </Grid.Column>

                                {section.subComponent2 &&
                                    <Grid.Column textAlign="left" width={3}
                                                 className='grid-column-2'>
                                        {section.p_up2 && <p className="sub-text">{section.p_up2}</p>}
                                        {section.subComponent2}
                                    </Grid.Column>
                                }

                            </Grid.Row>
                            <Divider/>
                        </Fragment>
                    )
                ))}
            </Grid>
        </div>
    );
};

export default OrdersSync;
