import 'semantic-ui-css/semantic.min.css'
import {Sidebar, Menu, Header, Dimmer, Loader} from 'semantic-ui-react'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SideBarMain from "./SideBarMain";
import {setActiveSettingId} from "../redux/appActions";
import {getCountSettings} from "../utils/Requests";
import {showErrorToast} from "../utils/utils";

function SideBarAccounts() {
    const theme = useSelector(state => state.app.theme);
    const [visible, setVisible] = useState(false);
    const activeSettingsId = useSelector(state => state.app.activeSettingsId);
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.app.accountId)
    const [countSettings, setCountSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentDate = new Date();

    const loadCountSetting = data => {
        if (!data.hasOwnProperty('settings')) {
            showErrorToast("Что то пошло не так, обратитесь к администрации");
            return console.error("error loadCountSetting data");
        }
        const _countSettings = data['settings'];
        const primarySetting = _countSettings.find(s => s['is_primary'] === 1);
        if (primarySetting) {
            const id_primary = primarySetting.id;
            setCountSettings(_countSettings);
            if (!activeSettingsId || !_countSettings.some((value) => value.id === activeSettingsId)) {
                dispatch(setActiveSettingId(id_primary));
            }
        } else {
            showErrorToast("Что то пошло не так, обратитесь к администрации");
            return console.error("error loadCountSetting primarySetting");
        }
    }


    useEffect(() => {
        if (!accountId) return;
        console.log("getCountSettings " + accountId);
        getCountSettings(accountId)
            .then(loadCountSetting)
            .catch(error => {
                showErrorToast(error.message, theme);
                console.error(error.message);
            })
            .finally();
        // eslint-disable-next-line
    }, [accountId]);


    const setSideBarAccountsData = (data) => {
        const newSettings = countSettings.map((value) => {
            if (value.id !== activeSettingsId) return value;
            const temp = value;
            temp.name = data.name;
            temp.token = data.token;
            return temp;
        });
        setCountSettings(newSettings);
    }

    const clickAccountMenuItem = (setting) => {
        if (setting.id === activeSettingsId) {
            setVisible(false);
            return;
        }
        dispatch(setActiveSettingId(setting.id));
        setLoading(true);
        setVisible(false)
    }

    return (
        <>
            {loading && <>
                <Dimmer active verticalAlign='top'>
                    <Loader style={{top: '300px',}}>Загрузка</Loader>
                </Dimmer>
                {/*<Image src='/images/wireframe/short-paragraph.png' />*/}
            </>}
            <Sidebar.Pushable
                style={{minHeight: '100vh'}}>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted={theme === 'dark'}
                    vertical
                    onHide={() => setVisible(false)}
                    visible={visible && countSettings.length > 1}

                    style={{minHeight: '100vh'}}
                >
                    <Menu.Item>
                        <Header inverted={theme === 'dark'} as='h3'>Аккаунты</Header>
                    </Menu.Item>
                    {countSettings.length > 1 && countSettings.map((setting, index) => {
                            let text = "Главный";
                            let color = null;
                            if (setting.is_primary === 0) {

                                const dateTimeString = setting.subscription_end_date;
                                const endDateTime = new Date(dateTimeString.replace(' ', 'T'));
                                if (endDateTime > currentDate) text = `Подписка до ${setting.subscription_end_date}`;
                                else {
                                    text = 'Подписка закончилась';
                                    color = 'red';
                                }
                            }

                            return (<Menu.Item
                                key={setting.id}
                                as={'a'}
                                active={setting.id === activeSettingsId}
                                onClick={() => clickAccountMenuItem(setting)}>
                                <p style={{marginBottom: '0px'}}>{setting.name ? setting.name : `Аккаунт ${index + 1}`}</p>
                                <p className='sub-text' style={{marginTop: '0px', color}}>{text}</p>
                            </Menu.Item>)
                        }
                    )}

                </Sidebar>
                <Sidebar.Pusher dimmed={visible}>
                    <SideBarMain setLoading={setLoading} setSideBarAccountsData={setSideBarAccountsData}
                                 onSideBarAccountVisible={() => countSettings.length > 1 && setVisible(true)}></SideBarMain>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>

    );
}

export default SideBarAccounts;
