import React, {useEffect, useState} from 'react'
import {Input} from 'semantic-ui-react'

const PhoneNumber = ({phone, onPhoneNumberChange = (value) => {}}) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        setPhoneNumber(phone);
    }, [phone]);

    const handleChange = (e) => {
        const value = e.target.value;
        let formattedValue = value.replace(/[^+\d]/g, ''); // Удаляем все символы, кроме + и цифр

        const limit = formattedValue[0] === '+' ? 12 : 11;

        if (formattedValue.length <= limit) {
            setPhoneNumber(formattedValue);
            onPhoneNumberChange(formattedValue);
        }
    };

    return (
        <Input
            placeholder='+7XXXXXXXXXX'
            value={phoneNumber}
            onChange={handleChange}
        />
    )
}

export default PhoneNumber;