import React, {useReducer, useEffect} from 'react';
import {Dropdown, Divider, Grid, Checkbox} from 'semantic-ui-react';
import {useSelector} from "react-redux";

const initialDocumentsState = {
    createIncomingPaymentStatus: null,
    deleteIncomingPayment: false,
    createShipment: [],
    deleteShipment: false,
    createReturn: null,
    autoCreateOutgoingPayment: false,
};

function documentsReducer(state, action) {
    switch (action.type) {
        case 'set':
            return {...state, [action.field]: action.value};
        case 'set_all':
            return {...state, ...action.payload};
        case 'reset':
            return action.data;
        default:
            throw new Error();
    }
}

const OrdersDocuments = ({
                             dataDocuments = null, statuses = [], onChangeItems = () => {
    }
                         }) => {
    const [state, dispatch] = useReducer(documentsReducer, initialDocumentsState);
    const theme = useSelector(state => state.app.theme);

    const statusesMulti = statuses.map(field => ({
        key: field.id,
        text: field.name,
        value: field.id,
    }));

    const statusesSelect = [{text: 'Не выбрано', value: null, key: null}, ...statusesMulti];

    useEffect(() => {
        if (dataDocuments) {
            dispatch({type: "set_all", payload: dataDocuments});
        }
    }, [dataDocuments]);

    useEffect(() => {
        onChangeItems(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const handleInputChange = (field) => (_, {value}) => {
        dispatch({type: 'set', field, value});
    };

    const handleToggleChange = (field) => () => {
        dispatch({type: 'set', field, value: !state[field]});
    };

    return (
        <div style={{textAlign: 'left'}}>
            <Grid style={{marginTop: '5px'}}>
                <Grid.Row>
                    <Grid.Column width={4} style={{minWidth: '400px'}}>
                        <strong>Создавать входящий платеж</strong>
                        <p className='over-text'>Выберите на каком этапе автоматически создавать "Документ - Входящий
                            платеж"</p>
                    </Grid.Column>
                    <Grid.Column width={3} style={{minWidth: '400px'}}>
                        <Dropdown
                            search
                            placeholder='Выберите статус'
                            fluid
                            selection
                            options={statusesSelect}
                            value={state.createIncomingPaymentStatus}
                            onChange={handleInputChange('createIncomingPaymentStatus')}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={4} style={{minWidth: '400px'}}>
                        <strong>Удалять входящий платеж при отмене заказа</strong>
                    </Grid.Column>
                    <Grid.Column width={3} style={{minWidth: '400px'}}>
                        <Checkbox className={theme} toggle checked={state.deleteIncomingPayment}
                                  onChange={handleToggleChange('deleteIncomingPayment')}></Checkbox>
                    </Grid.Column>
                </Grid.Row>

                <Divider></Divider>

                <Grid.Row>
                    <Grid.Column width={4} style={{minWidth: '400px'}}>
                        <strong>Создавать отгрузку</strong>
                        <p className='over-text'>Выберите на каких этапах автоматически создавать "Документ -
                            Отгрузка"</p>
                    </Grid.Column>
                    <Grid.Column width={3} style={{minWidth: '400px'}}>
                        <Dropdown
                            search
                            placeholder='Выберите статусы'
                            fluid
                            selection
                            multiple={true}
                            options={statusesMulti}
                            value={state.createShipment || []}
                            onChange={handleInputChange('createShipment')}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={4} style={{minWidth: '400px'}}>
                        <strong>Удалять отгрузку при отмене заказа</strong>
                    </Grid.Column>
                    <Grid.Column width={3} style={{minWidth: '400px'}}>
                        <Checkbox className={theme} toggle checked={state.deleteShipment}
                                  onChange={handleToggleChange('deleteShipment')}></Checkbox>
                    </Grid.Column>
                </Grid.Row>

                <Divider></Divider>

                <Grid.Row>
                    <Grid.Column width={4} style={{minWidth: '400px'}}>
                        <strong>Создавать возврат</strong>
                        <p className='over-text'>Выберите на каком этапе автоматически создавать "Документ -
                            Возврат"</p>
                    </Grid.Column>
                    <Grid.Column width={3} style={{minWidth: '400px'}}>
                        <Dropdown
                            search
                            placeholder='Выберите статус'
                            fluid
                            selection
                            options={statusesSelect}
                            value={state.createReturn}
                            onChange={handleInputChange('createReturn')}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={4} style={{minWidth: '400px'}}>
                        <strong>Автоматически создавать исходящий платеж</strong>
                    </Grid.Column>
                    <Grid.Column width={3} style={{minWidth: '400px'}}>
                        <Checkbox className={theme} toggle checked={state.autoCreateOutgoingPayment}
                                  onChange={handleToggleChange('autoCreateOutgoingPayment')}></Checkbox>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default OrdersDocuments;
