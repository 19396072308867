import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'

export const DropdownHeader = ({fields = [] ,
                               activeOptions = null,
                               onOptionsChange = () => {},
                               multiSelect = false,
                               loading = false,
                               search = false,
                               selection = true}) => {

    return (
        <Dropdown
            className='select-header'
            icon={<Icon name='dropdown' style={{transform: 'rotate(90deg)'}}/>} // отключение стандартной иконки
            placeholder='Выберите поле'
            fluid
            loading={loading}
            multiple={multiSelect}
            search={search}
            selection={selection}
            options={fields}
            value={activeOptions || fields[0]?.value}
            onChange={(event, { value }) => {
                onOptionsChange(value);
            }}
        >
        </Dropdown>
    )
}