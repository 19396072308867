import {Sidebar, Segment, Menu, Icon, Image} from 'semantic-ui-react'
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import HomeMenu from "./home/HomeMenu";
import OrdersLeftSideBar from "./orders/OrdersLeftSideBar";
import SatuSvg from '../img/satu.png';
import {ExportAccordion} from "./product/ExportAccordion";
import {getMoySkladData, getSetting} from "../utils/Requests";
import {showErrorToast} from "../utils/utils";

function SideBarMain({
                         setLoading = (value) => {
                         }, onSideBarAccountVisible = () => {
    }, setSideBarAccountsData = () => {
    }
                     }) {
    const theme = useSelector(state => state.app.theme);
    const [activeItem, setActiveItem] = useState('home');
    const handleItemClick = (name) => setActiveItem(name);
    const themeClass = theme === 'dark' ? 'secondSideBar theme-dark-segment' : 'secondSideBar theme-light-segment';
    const activeSettingsId = useSelector(state => state.app.activeSettingsId);
    const [setting, setSetting] = useState({});
    const accountId = useSelector(state => state.app.accountId);

    const [stateProductArray, setStateProductArray] = useState({
        productAttributes: [],
    });

    const [stateOrderArray, setStateOrderArray] = useState({
        customerOrderRequiredAttributes: [],
        customerOrderAttributes: [],
        counterpartyArray: [],
        organizationsArray: [],
        priceTypeArray: [],
        saleschannelArray: [],
        currencyArray: [],
        projectArray: [],
        employeeArray: [],
        groupArray: [],
        storeArray: [],
        statesArray: [],

    });
    const setOrderArrayData = (key, data) => {
        setStateOrderArray(prevState => ({...prevState, [key]: data}));
    }

    const setProductArrayData = (key, data) => {
        setStateProductArray(prevState => ({...prevState, [key]: data}));
    }


    useEffect(() => {
        if (!accountId) return;
        Promise.all([
            getMoySkladData('customerorder/attributes/required', accountId, data => setOrderArrayData('customerOrderRequiredAttributes', data)),
            getMoySkladData('customerorder/attributes', accountId, data => setOrderArrayData('customerOrderAttributes', data)),
            getMoySkladData('product/attributes', accountId, data => setProductArrayData('productAttributes', data)),
            getMoySkladData('customerorder/states', accountId, data => setOrderArrayData('statesArray', data)),
            getMoySkladData('pricetype', accountId, data => setOrderArrayData('priceTypeArray', data)),
            getMoySkladData('store', accountId, data => setOrderArrayData('storeArray', data)),
            getMoySkladData('organization', accountId, data => setOrderArrayData('organizationsArray', data)),
            getMoySkladData('saleschannel', accountId, data => setOrderArrayData('saleschannelArray', data)),
            getMoySkladData('currency', accountId, data => setOrderArrayData('currencyArray', data)),
            getMoySkladData('project', accountId, data => setOrderArrayData('projectArray', data)),
            getMoySkladData('employee', accountId, data => setOrderArrayData('employeeArray', data)),
            getMoySkladData('group', accountId, data => setOrderArrayData('groupArray', data)),
        ]).then().catch(error => console.log(error.message));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId])



    const loadSettings = useCallback(() => {
        if (!accountId) return;
        if (!activeSettingsId) return;
        console.log("Загружаем настройки ");
        setLoading(true);
        getSetting(activeSettingsId, accountId)
            .then(setSetting)
            .catch(error => {
                showErrorToast(error.message, theme);
                console.error(error);
            })
            .finally(() => {
                  setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, activeSettingsId, theme]);



    useEffect(() => {
        if (!activeSettingsId) return;
        loadSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSettingsId, accountId])


    return (
        <Sidebar.Pushable
            style={{minHeight: '100vh',}}>
            <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                inverted
                vertical
                visible={true}
                width='very thin'
                style={{minHeight: '100vh'}}
                className='mainSideBar'
            >
                <Menu.Item
                    as={'a'}
                    name='accounts'
                    onClick={onSideBarAccountVisible}

                >
                    <div>
                        <Image src={SatuSvg} style={{maxWidth: '30px'}}/>
                    </div>
                </Menu.Item>
                <Menu.Item
                    as={'a'}
                    name='home'
                    active={activeItem === 'home'}
                    onClick={() => handleItemClick('home')}
                >
                    <div>
                        <Icon name='home' size='big'/>
                        <label style={{fontSize: '0.7em'}}>Главная</label>
                    </div>
                </Menu.Item>
                <Menu.Item
                    as={'a'}
                    name='orders'
                    active={activeItem === 'orders'}
                    onClick={() => handleItemClick('orders')}
                >
                    <div>
                        <Icon name='sync' size='big'/>
                        <label style={{fontSize: '0.7em'}}>Заказы</label>
                    </div>
                </Menu.Item>

                <Menu.Item
                    as={'a'}
                    name='product'
                    active={activeItem === 'product'}
                    onClick={() => handleItemClick('product')}
                >
                    <div>
                        <Icon name='shopping cart' size='big'/>
                        <label style={{fontSize: '0.7em'}}>Товары</label>
                    </div>
                </Menu.Item>

            </Sidebar>
            <Sidebar.Pusher style={{marginLeft: '60px'}}>
                <Segment basic
                         className={themeClass}>
                    <div style={{display: activeItem === 'home' ? 'block' : 'none'}}>
                        <HomeMenu setSideBarAccountsData={setSideBarAccountsData} settingMain={setting?.main}
                                  loadSettings={loadSettings}/>
                    </div>
                    <div style={{display: activeItem === 'orders' ? 'block' : 'none'}}>
                        <OrdersLeftSideBar
                            settingOrders={setting?.orders}
                            stateOrderArray={stateOrderArray}
                            setOrderArrayData={setOrderArrayData}
                        />
                    </div>

                    <div style={{display: activeItem === 'product' ? 'block' : 'none'}}>
                        <ExportAccordion
                            stateOrderArray={stateOrderArray}
                            stateProductArray={stateProductArray}
                            exportProducts={setting?.exportProducts}
                            exportStatus={setting?.exportStatus}
                        ></ExportAccordion>
                    </div>
                </Segment>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
}

export default SideBarMain;
