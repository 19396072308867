import {Button, Icon, Table} from "semantic-ui-react";
import {formatDate} from "../../../utils/utils";
import React, {useEffect, useState} from "react";
import {getLogAccounts} from "../../../utils/Requests";
import {useSelector} from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function LogPage() {
    const theme = useSelector(state => state.app.theme);
    const accountId = useSelector(state => state.app.accountId);
    const [logAccounts, setLogAccounts] = useState([]);
    const [loadingButton, setLoadingButton] = useState(true);
    const themeClass = theme === 'dark' ? 'theme-dark-segment' : 'theme-light-segment';

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        ws['!cols'] = [];
        for(let i = 0; i < 6; i++) {
            ws['!cols'].push({ wch: 50 });
        }
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const reflesh = () => {
        getLogAccounts(accountId)
            .then(value => setLogAccounts(value))
            .catch(error => console.log(error.message))
            .finally(() => setLoadingButton(false));
    }

    useEffect(() => {
        if (logAccounts.length > 0) return;
        reflesh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const clickRefleshButton = () => {
        setLoadingButton(true);
        reflesh();
    }

    const exportLog = () => {
        const newLogAccounts = logAccounts.map((item) => {
            item.updated_at = formatDate(item.updated_at);
           return item;
        });
        exportToCSV(newLogAccounts, 'log');
    }

    return (<div
                 className={themeClass}>

            <Button
                style={{marginBottom: '10px'}}
                floated='right'
                inverted={theme === 'dark'}
                basic
                loading={loadingButton}
                icon='sync'
                size='medium'
                content='Обновить'
                onClick={clickRefleshButton}
            ></Button>
            <Button
                style={{marginBottom: '10px'}}
                floated='right'
                inverted={theme === 'dark'}
                basic
                color='violet'
                onClick={exportLog}
            >
                <Icon name='file excel outline' /> Скачать

            </Button>
            <Table
                inverted={theme === 'dark'} >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}>Дата и время</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Действие</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Команда</Table.HeaderCell>
                        <Table.HeaderCell width={5}>Файл</Table.HeaderCell>
                        <Table.HeaderCell width={5}>Ошибка</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Строка</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body className='tbody-overflow-wrap'>
                    {
                        Array.isArray(logAccounts) && logAccounts.map((item, index) => {
                            return (<Table.Row key={item.line + item.command}>
                                <Table.Cell>{formatDate(item.updated_at).toLocaleString()}</Table.Cell>
                                <Table.Cell>{item.operation}</Table.Cell>
                                <Table.Cell>{item.command}</Table.Cell>
                                <Table.Cell>{item.file}</Table.Cell>
                                <Table.Cell>{item.error}</Table.Cell>
                                <Table.Cell>{item.line}</Table.Cell>
                            </Table.Row>)
                        })
                    }

                </Table.Body>
            </Table>
        </div>

    );
}

export default LogPage;
