import {Accordion, Button, Checkbox, Grid, Header, Icon, Segment} from "semantic-ui-react";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setExporAccordiontActiveIndex} from "../../redux/appActions";
import {DropdownHeader} from "../formControls/DropdownHeader";
import ExportProduct from "./ExportAccordionContent/ExportProduct";
import UpdateInfoProduct from "./ExportAccordionContent/UpdateInfoProduct";
import {getSettingExportStatus, isRunningExport, pathSettings, runExport} from "../../utils/Requests";
import ExportProductsReport from "./ExportAccordionContent/ExportProductsReport";
import {showErrorToast, showSuccessToast} from "../../utils/utils";


export const ExportAccordion = ({stateOrderArray, exportProducts, stateProductArray, exportStatus = []}) => {
    const activeIndex = useSelector(state => state.app.exportAccordionActiveIndex || []);
    const activeSettingsId = useSelector(state => state.app.activeSettingsId);
    const accountId = useSelector(state => state.app.accountId);
    const theme = useSelector(state => state.app.theme);
    const themeClass = theme === 'dark' ? 'theme-dark-segment' : 'theme-light-segment';
    const dispatch = useDispatch();
    const [onlyFile, setOnlyFile] = useState(0);


    const [timerIsRunningExportActive, setTimerIsRunningExportActive] = useState(false);
    const [exportStatusLocal, setExportStatusLocal] = useState([]);

    const handleClick = useCallback((e, titleProps) => {
        const {index} = titleProps;
        const newIndex = activeIndex.includes(index)
            ? activeIndex.filter(i => i !== index)
            : [...activeIndex, index];
        dispatch(setExporAccordiontActiveIndex(newIndex));
    }, [activeIndex, dispatch]);


    useEffect(() => {
        if (Array.isArray(exportStatus) && JSON.stringify(exportStatus) !== JSON.stringify(exportStatusLocal)) {
            setExportStatusLocal(exportStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportStatus])


    const [stateExportProducts, setStateExportProducts] = useState({
        is_sync: false,
        scheduler: null,
        force_update: false,
        only_available: false,
        mark_missing_product_as: 'none',
        mods_as_variety: false,
        unload_goods_field: null,
        unload_goods_type: 'not_checked',
        link_XLSX_File: '',
        sku: false,
        sku_field: null,
        price: false,
        price_field: null,
        price_wholesale: false,
        price_wholesale_field: null,
        min_order_for_price_wholesale: false,
        min_order_for_price_wholesale_field: null,
        images_urls: false,
        images_urls_count: '1',
        presence: false,
        presence_field: null,
        quantity_in_stock: false,
        quantity_in_stock_field: null,
        quantity_in_stock_stores_field: [],
        delete_zero_stock: false,
        group: false,
        keywords: false,
        keywords_field: null,
        discount: false,
        discount_field: null,
        labels: false,
        labels_field: null,
        gtin: false,
        mpn: false,
        mpn_field: null,
        manufacturer: false,
        manufacturer_field: null,
        attributes_field: [{text: '', value: null}],
    });

    useEffect(() => {
        if (!exportProducts) return;


        const getOrDefault = (key, defaultValue = [{text: '', value: null}]) => {
            if (!exportProducts.hasOwnProperty(key)) return defaultValue;
            const parsedValue = JSON.parse(exportProducts[key]);
            return parsedValue || defaultValue;
        };

        const quantity_in_stock_stores_field = JSON.parse(exportProducts['quantity_in_stock_stores_field']) || [];

        setStateExportProducts(prevState => ({
            ...prevState,
            ...exportProducts,
            quantity_in_stock_stores_field,
            attributes_field: getOrDefault('attributes_field'),
        }));

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportProducts])


    const setStateExportProductsData = (key, data) => {
        setStateExportProducts(prevState => ({...prevState, [key]: data}));
    }



    const onSave = () => {
        const data = {
            exportProducts: stateExportProducts
        }
        pathSettings(data, activeSettingsId, accountId)
            .then(() => showSuccessToast("Сохранено!", theme))
            .catch(error => showErrorToast(error.message, theme));
    }


    const clickRunExport = () => {
        setOnlyFile(0);
        runExport(activeSettingsId, accountId).then(
            () => {
                setTimerIsRunningExportActive(true);
                showSuccessToast("Задача запущена", theme)
            }
        )
            .catch(error => showErrorToast(error.message, theme));
    }

    const clickOnlyFile = () => {
        setOnlyFile(1);
        runExport(activeSettingsId, accountId, 1).then(
            () => {
                setTimerIsRunningExportActive(true);
                showSuccessToast("Задача запущена", theme)
            }
        )
            .catch(error => showErrorToast(error.message, theme));
    }




    useEffect(() => {
        let intervalId;

        if (timerIsRunningExportActive) {
            intervalId = setInterval(() => {
                isRunningExport(activeSettingsId, accountId, onlyFile).then(
                    result => {
                        if (!result) {
                            showSuccessToast("Задача выполнена!", theme);
                            setTimerIsRunningExportActive(false);
                        }
                    }
                )
                    .catch(error => {
                            setTimerIsRunningExportActive(false);
                            showErrorToast(error.message, theme)
                        }
                    )
            }, 5000);
        }

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerIsRunningExportActive]);


    const clickRefresh = () => {
        getSettingExportStatus(activeSettingsId, accountId)
            .then((newExportStatus) => {
                setExportStatusLocal(newExportStatus)
                showSuccessToast("Обновлено", theme);
            }).catch(error => showErrorToast(error.message, theme));
    }

    return (
        <div className={themeClass}>
            <Segment basic className={themeClass}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Accordion>

                                <Accordion.Title
                                    active={activeIndex.includes(0)}
                                    index={0}
                                    onClick={handleClick}
                                >

                                    <Header inverted={theme === 'dark'} as='h3'>
                                        <Icon name='dropdown'/>
                                        Экспорт товаров
                                    </Header>

                                    <Grid className='grid-header' onClick={(e) => e.stopPropagation()}>
                                        <Grid.Row verticalAlign='middle'>
                                            <Grid.Column width={1} textAlign='left'>
                                                <Checkbox className={theme} toggle
                                                          checked={Boolean(stateExportProducts.is_sync)}
                                                          onChange={
                                                              () => setStateExportProductsData('is_sync', !Boolean(stateExportProducts.is_sync))}/>

                                            </Grid.Column>
                                            <Grid.Column width={4} textAlign='left'>
                                                <DropdownHeader
                                                    fields={[
                                                        {id: 'day', value: 'day', text: "Раз в день"},
                                                        // {id: 'hour', value: 'hour', text: "Раз в час"},
                                                        {id: 'hour4', value: 'hour4', text: "Раз в 4 часа"},
                                                        {id: 'week', value: 'week', text: "Раз в неделю"},
                                                        {id: 'month', value: 'month', text: "Раз в месяц"},
                                                    ]}
                                                    activeOptions={stateExportProducts.scheduler}
                                                    onOptionsChange={value => setStateExportProductsData('scheduler', value)}
                                                ></DropdownHeader>

                                            </Grid.Column>
                                            <Grid.Column width={11} textAlign='right' verticalAlign='middle'>
                                                {timerIsRunningExportActive &&
                                                    <Icon inverted={theme === 'dark'} loading name='spinner'/>}
                                                <Button inverted={theme === 'dark'}
                                                        primary
                                                        content='Сохранить'
                                                        onClick={() => onSave()}
                                                        floated='right'/>



                                                <Button
                                                        className={timerIsRunningExportActive ? 'link-disabled' : 'link'}
                                                        onClick={clickRunExport}
                                                >
                                                    Экспортировать сейчас
                                                </Button>


                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Accordion.Title>

                                <Accordion.Content active={activeIndex.includes(0)}>
                                    <Segment inverted={theme === 'dark'}>
                                        <ExportProduct
                                            visibleLink={!timerIsRunningExportActive}
                                            productAttributes={stateProductArray.productAttributes}
                                            stateExportProducts={stateExportProducts}
                                            setStateExportProductsData={setStateExportProductsData}
                                            clickOnlyFile={clickOnlyFile}
                                        ></ExportProduct>
                                    </Segment>
                                </Accordion.Content>

                                <Accordion.Title
                                    active={activeIndex.includes(1)}
                                    index={1}
                                    onClick={handleClick}
                                >
                                    <Header inverted={theme === 'dark'} as='h3'>
                                        <Icon name='dropdown'/>
                                        Обновление информации
                                    </Header>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex.includes(1)}>
                                    <Segment inverted={theme === 'dark'}>
                                        <UpdateInfoProduct
                                            stateOrderArray={stateOrderArray}
                                            productAttributes={stateProductArray.productAttributes}
                                            stateExportProducts={stateExportProducts}
                                            setStateExportProductsData={setStateExportProductsData}
                                        ></UpdateInfoProduct>
                                    </Segment>
                                </Accordion.Content>

                                <Accordion.Title
                                    active={activeIndex.includes(2)}
                                    index={2}
                                    onClick={handleClick}
                                >
                                    <Header inverted={theme === 'dark'} as='h3'>
                                        <Icon name='dropdown'/>
                                        Отчет по импорту
                                    </Header>

                                    <Grid className='grid-header' onClick={(e) => e.stopPropagation()}>
                                        <Grid.Row verticalAlign='middle'>
                                            <Grid.Column width={16} textAlign='right' verticalAlign='middle'>
                                                <Button className='link' onClick={clickRefresh}>Обновить</Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex.includes(2)}>
                                    <ExportProductsReport exportStatus={exportStatusLocal}></ExportProductsReport>
                                </Accordion.Content>

                            </Accordion>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </div>
    )
}