import React, {useEffect, useState} from 'react';
import {Dropdown, Divider, Grid, Input} from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';
import { registerLocale } from  "react-datepicker";
import {isURL} from "../../../utils/utils";
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru)

const OrdersRequiredAttributesForm = ({attributes = [], onChangeItems = (values) => {}}) => {
    const [values, setValues] = useState({});
    const [attributesFormat, setAttributesFormat] = useState(attributes);
    const defaultUrl = 'https://localhost';

    const handleChange = (index, newValue, type) => {
        setValues(prevValues => ({...prevValues, [index]: {value: newValue, type}}));
    };

    const handleChangeDate = (index, date, type) => {
        if (!date) date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000`;

        setValues(prevValues => ({...prevValues, [index]: {value: formattedDate, type}}));

    };

    const handleBlurLink = (index, e, type) => {
        if (!isURL(e.target.value)) {
            setValues(prevValues => ({...prevValues, [index]: {value: defaultUrl, type}}));
        }
    }

    useEffect(() => {
        onChangeItems(values);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])


    useEffect(() => {
        //  Формируем аттрибуты
        const newAttributesFormat = attributes.map((item, index) => {
            const setDefoltText = (value) => setValues(prevValues => ({
                ...prevValues,
                [item.id]: {value: item.value || value, type: item.type}
            }));

            switch (item.type) {
                case 'string':
                    setDefoltText('Просто текст');
                    return item;
                case 'text':
                    setDefoltText('Просто текст')
                    return item;
                case 'link':
                    setDefoltText(defaultUrl)
                    return item;
                case 'long':
                    setDefoltText(1)
                    return item;
                case 'double':
                    setDefoltText(1.1)
                    return item;
                case 'time':
                    setDefoltText('2023-05-02 05:42:59.000');

                    return item;
                default:
                    // Создаем правильный формат опций для Dropdown
                    const options = item.values.map((value, index) => {
                        return {
                            key: index,
                            text: value.text,
                            value: JSON.stringify(value.id)
                        }
                    });
                    // устанавливаем значения по деволту, если значение присутствует в базе, то берем его, если нет то первое из списка
                    if (item.value.length > 2) {
                        setValues(prevValues => ({
                                ...prevValues,
                                [item.id]: {
                                    value: item.value,
                                    type: item.type
                                },
                            }
                        ));
                    } else setValues(prevValues => ({
                        ...prevValues,
                        [item.id]: {
                            value: JSON.stringify(item.values[0].id),
                            type: item.type
                        }
                    }));

                    // Возвращаем новый элемент dropdown
                    return {...item, values: options, value: {value: item.value, type: item.type}}
            }

        })

        setAttributesFormat(newAttributesFormat);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes])

    return (
        <div style={{textAlign: 'left'}}>
            <Grid style={{marginTop: '5px'}}>
                {attributesFormat.map((item, index) => {
                    const renderInput = () => {
                        switch (item.type) {
                            case 'string':
                                return <Input fluid value={values[item.id].value || item.value}
                                              placeholder='Введите текст' key={item.id}
                                              onChange={e => handleChange(item.id, e.target.value, item.type)}/>;
                            case 'text':
                                return <Input fluid value={values[item.id].value || item.value}
                                              placeholder='Введите текст' key={item.id}
                                              onChange={e => handleChange(item.id, e.target.value, item.type)}/>;
                            case 'link':
                                return <Input fluid value={values[item.id].value || item.value}
                                              onBlur={e => handleBlurLink(item.id, e, item.type)}
                                              placeholder="Введите ссылку" key={item.id}
                                              onChange={e => handleChange(item.id, e.target.value, item.type)}/>;
                            case 'long':
                                return <Input fluid type="text" value={values[item.id].value || item.value}
                                              placeholder='Введите целое число' key={item.id}
                                              onChange={(event) => {
                                                  const {value} = event.target;
                                                  if (!/^[-+]?[0-9]*$/.test(value)) {
                                                      event.preventDefault();
                                                      return;
                                                  }
                                                  handleChange(item.id, value, item.type)
                                              }}/>;
                            case 'double':
                                return (
                                    <Input fluid type="number" step="0.01" value={values[item.id].value || item.value}
                                           placeholder="ВВедите число с точкой, например 1.1" key={item.id}
                                           onChange={e => handleChange(item.id, e.target.value, item.type)}/>
                                );
                            case 'time':

                                return <DateTimePicker locale="ru"
                                                       className='date-time-picker'
                                                       value={values[item.id].value || item.value}
                                                       key={item.id}
                                                       onChange={date => handleChangeDate(item.id, date, item.type)}/>;
                            default:
                                return <Dropdown
                                    search
                                    fluid
                                    selection
                                    options={item.values}
                                    value={values[item.id].value}
                                    onChange={(_, {value}) => {
                                        handleChange(item.id, value, item.type)
                                    }}
                                ></Dropdown>;
                        }
                    };

                    return (
                        <React.Fragment key={index}>
                            <Grid.Row>
                                <Grid.Column
                                    verticalAlign="middle"
                                    width={3}
                                    style={{minWidth: '400px'}}
                                >
                                    <strong >
                                        {item.name}
                                    </strong>
                                </Grid.Column>
                                <Grid.Column
                                    textAlign="left"
                                    verticalAlign="middle"
                                    width={3}
                                    style={{minWidth: '400px', paddingTop: '5px'}}
                                >
                                    {renderInput()}
                                </Grid.Column>
                            </Grid.Row>
                            <Divider/>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </div>
    );
};

export default OrdersRequiredAttributesForm;
