export const setAccountId = (accountId) => ({
    type: 'SET_ACCOUNT_ID',
    payload: accountId,
});

export const setThemeNight = () => ({
    type: 'SET_THEME',
    payload: 'dark',
});

export const setThemeLight = () => ({
    type: 'SET_THEME',
    payload: 'light',
});

export const setOrdersAccordionActiveIndex = (activeIndex) => ({
    type: 'SET_ORDERS_ACCORDION_ACTIVE_INDEX',
    payload: activeIndex,
});

export const setExporAccordiontActiveIndex = (activeIndex) => ({
    type: 'SET_EXPORT_ACCORDION_ACTIVE_INDEX',
    payload: activeIndex,
});



export const setActiveSettingId = (id) => ({
    type: 'SET_ACTIVE_SETTINGS_ID',
    payload: id,
});