import {toast} from "react-toastify";

export function isURL(str) {
    let url;

    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}


export function formatDate(date) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Asia/Almaty' // Dhaka timezone corresponds to UTC+6
    };
    return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
}


export const showSuccessToast = (message, theme = 'light') => {
    toast.success(message, {
        theme,
        autoClose: 1000,
        position: "top-center",
    });
}

export const showErrorToast = (error, theme = 'light') => {
    toast.error(error, {
        theme,
        autoClose: 1000,
        position: "top-center",
    });
}