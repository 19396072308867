export async function fetchApi(url, method, headers = {}, body = null) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: body ? JSON.stringify(body) : undefined
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
}

export async function pathSettings(postData, id, accountId) {
    const data = await fetchApi(`/api/settings/${id}`, 'PATCH', { 'Authorization': `Bearer ${accountId}` }, postData);

    if (!data['success']) {
        throw new Error(data['error']);
    }
}

export async function getUserContext(contextKey) {
    const data = await fetchApi(`/api/context/${contextKey}`, 'GET');

    if (data['success']) {
        return data['accountId'];
    } else {
        throw new Error('Не авторизирован, обратитесь к администрации');
    }
}

export async function getSetting(id, accountId) {
    const responseData = await fetchApi(`/api/settings/${id}`, 'GET', { 'Authorization': `Bearer ${accountId}` });

    if (responseData && responseData.success) {
        return responseData.settings;
    } else {
        throw new Error('Ошибка загрузки настроек');
    }
}

export async function getCountSettings(accountId) {
    const data = await fetchApi(`/api/settings/count`, 'GET', { 'Authorization': `Bearer ${accountId}` });

    if (data['success']) {
        return data;
    } else {
        throw new Error(`success === false`);
    }
}

export async function getMoySkladData(url, accountId, setter = (data) => {}) {
    console.log("Запрашиваем " + url);
    const data = await fetchApi(`/api/moysklad/${url}`, 'GET', { 'Authorization': `Bearer ${accountId}` });

    if (data['success']) {
        setter(data['data']);
    } else {
        throw new Error(`success === false`);
    }
}

export async function runExport(id, accountId, only_file = 0) {

    const responseData = await fetchApi(`/api/export-product-now/${id}/${only_file}`, 'GET', { 'Authorization': `Bearer ${accountId}` });

    if (!responseData) {
        throw new Error('Нет ответа');
    }
    if (responseData.success) {
        // Действия при успешном выполнении запроса
    } else {
        throw new Error(responseData.error);
    }
}

export async function isRunningExport(id, accountId, only_file = 0) {
    const responseData = await fetchApi(`/api/export-product-is-running/${id}/${only_file}`, 'GET', { 'Authorization': `Bearer ${accountId}` });

    if (!responseData) {
        throw new Error('Нет ответа');
    }
    if (responseData.success) {
        return responseData.run;
    } else {
        throw new Error(responseData.error);
    }
}

export async function getSettingExportStatus(id, accountId) {
    const responseData = await fetchApi(`/api/settings/export-status/${id}`, 'GET', { 'Authorization': `Bearer ${accountId}` });

    if (!responseData) {
        throw new Error('Нет ответа');
    }
    if (responseData.success) {
        return responseData.exportStatus;
    } else {
        throw new Error(responseData.error);
    }
}

export async function getLogAccounts(accountId) {
    const responseData = await fetchApi(`/api/log-accounts`, 'GET', { 'Authorization': `Bearer ${accountId}` });

    if (!responseData) {
        throw new Error('Нет ответа');
    }
    if (responseData.success) {
        return responseData.logAccounts;
    } else {
        throw new Error(responseData.error);
    }
}

export async function getFileFromLink(link) {
    const response = await fetch(link);
    if (response.status !== 200) {
        throw new Error("response.status !== 200");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'your_file_name.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
}
