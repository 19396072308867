import React, {useEffect, useState} from 'react';
import {Dropdown, Divider, Grid} from 'semantic-ui-react';

const OrdersStatuses = ({dataStatuses = null, statuses = [], onChangeItems = () => {}}) => {
    const statusesFormat = statuses.map(field => {
        return {
            text: field.name,
            value: field.id,
            key: field.id
        }});

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);



    const handleDropdownChange = (index, value) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = value;
        setSelectedOptions(newSelectedOptions);
    };


    useEffect(() => {
        if (!dataStatuses) return;


        setOptions(Array(dataStatuses.length).fill(statusesFormat));
        const selectValueArray = Array(dataStatuses.length).fill(null);
        dataStatuses.forEach((item, index) => {
            selectValueArray[index] = item.value;
        })

        setSelectedOptions(selectValueArray);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataStatuses])

    useEffect(() => {
        const data = [];
        selectedOptions.forEach((item, index) => {
            data.push({
                satu_id: dataStatuses[index].id,
                satu_name: dataStatuses[index].name,
                moysklad_status_id: item
            });
        })


        onChangeItems(data);


        const newOptions = options.map((allOptions, indexAllOption) => {
            return [
                {text: 'Не выбрано', value: null}, // Add the "Не выбрано" option back
                ...statusesFormat.filter((value, indexDropdownOptions) => {
                    const indexSelectedOptions = selectedOptions.indexOf(value.value);
                    return !(indexSelectedOptions !== -1 && indexAllOption !== indexSelectedOptions);
                }),
            ];
        })


        setOptions(newOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions, statuses]);

    return (
        <div style={{textAlign: 'left'}}>
            <Grid style={{marginTop: '5px'}}>
                <Grid.Row>
                    <Grid.Column width={4} className='grid-column-1'>
                        <p className='over-text'>Статус заказа в Satu</p>
                    </Grid.Column>
                    <Grid.Column width={3} className='grid-column-1'>
                        <p className='over-text'>Статус заказа в МойСклад</p>
                    </Grid.Column>
                </Grid.Row>
                {dataStatuses && dataStatuses.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <Grid.Row>
                            <Grid.Column verticalAlign='middle' width={4} className='grid-column-1'>
                                <strong>{item.title}</strong>
                            </Grid.Column>
                            <Grid.Column verticalAlign='middle' width={3}
                                         className='grid-column-2'>
                                <Dropdown
                                    search
                                    placeholder='Выберите статус'
                                    fluid
                                    selection
                                    options={options[index] ?? []}
                                    value={selectedOptions[index]}
                                    onChange={(_, {value}) => handleDropdownChange(index, value)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Divider/>
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    );
};

export default OrdersStatuses;
