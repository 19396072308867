const initialState = {
    accountId: null,
    theme: 'light',
    activeSettingsId: null,
    ordersAccordionActiveIndex: [],
    exportAccordionActiveIndex: [],
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ACCOUNT_ID':
            return {
                ...state,
                accountId: action.payload,
            };
        case 'SET_THEME':
            return {
                ...state,
                theme: action.payload,
            };
        case 'SET_ACTIVE_SETTINGS_ID':
            return {
                ...state,
                activeSettingsId: action.payload,
            };

        case 'SET_ORDERS_ACCORDION_ACTIVE_INDEX':
            return {
                ...state,
                ordersAccordionActiveIndex: [...action.payload],
            };

        case 'SET_EXPORT_ACCORDION_ACTIVE_INDEX':
            return {
                ...state,
                exportAccordionActiveIndex: [...action.payload],
            };
        default:
            return state;
    }
};

export default appReducer;