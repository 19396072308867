import React from 'react'
import {Checkbox} from 'semantic-ui-react'
import {useSelector} from "react-redux";

const OrdersLeftSideBarItems = ({text, checked, onCheckChange = () => {}}) => {
    const theme = useSelector(state => state.app.theme);
    return (
        <>
            <div className='orders-left-sideBar-item'>
                {text}
                <Checkbox
                    className={theme}
                    checked={checked}  toggle onChange={onCheckChange}/>
            </div>
        </>
    )
}

export default OrdersLeftSideBarItems;