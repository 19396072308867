import {Grid, Header, Image, List, Segment} from 'semantic-ui-react'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Telegram from '../../../img/telegram.gif';

function SettingsPage() {
    const theme = useSelector(state => state.app.theme);
    const themeClass = theme === 'dark' ? 'theme-dark-segment' : 'theme-light-segment';

    return (
        <Grid className={themeClass} >
            <Grid.Row>
                <Grid.Column width={16}>
                    <Header inverted={theme === 'dark'}  as='h3' floated='left'>Контакты поддержки</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment inverted={theme === 'dark'} textAlign='left' >
                        <div style={{marginBottom: '10px'}}>
                            <strong style={{display:'block'}}>Время работы:</strong>
                            Пн-Пт с 9:00 до 18:00, Сб-Вс выходной по времени Алматы (GMT+6)
                        </div>


                        <div style={{marginBottom: '10px'}}>
                            <strong style={{display:'block'}}>Казахстан:</strong>
                            <Link to='#'>+77088210292</Link>
                        </div>


                        <div style={{marginBottom: '10px'}}>
                            <strong style={{display:'block'}}>E-mail:</strong>
                            <a href="mailto:hd@fixcom.kz">hd@fixcom.kz</a>
                        </div>


                        <div style={{marginBottom: '10px'}}>
                            <strong style={{display:'block'}}>Сайт:</strong>
                            <a href="https://fixcom.kz/" target="_blank" rel="noopener noreferrer">fixcom.kz</a>
                        </div>

                        <div style={{display: 'flex', marginBottom: '10px', }}>
                            <a href="https://t.me/fixcom_support_bot" target="_blank" rel="noopener noreferrer">
                                <Image src={Telegram} style={{weight:'30px', height:'30px', marginRight: '10px'}} alt="Image" />
                            </a>
                            {/*<a href="https://api.whatsapp.com/send/?phone=77088210292&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">*/}
                            {/*    <Image  src={Whatsapp} style={{weight:'30px', height:'30px'}} alt="Image" />*/}
                            {/*</a>*/}
                        </div>
                    </Segment>

                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <Header inverted={theme === 'dark'}  as='h3' floated='left'>Полезный материал</Header>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    <Segment inverted={theme === 'dark'} textAlign='left' >
                        <div style={{marginBottom: '10px'}}>

                            <List bulleted className='link-list' >
                                <List.Item><a href="https://t.me/Fixcom_MSK" target="_blank" rel="noopener noreferrer">Telegram канал с новостями о приложении</a></List.Item>
                                <List.Item><a href="https://fixcom.kz/faq/moysklad_satu" target="_blank" rel="noopener noreferrer">База знаний и руководство по настройке интеграции</a></List.Item>

                            </List>
                        </div>
                    </Segment>

                </Grid.Column>
            </Grid.Row>

            {/*<Grid.Row>*/}
            {/*    <Grid.Column width={16}>*/}
            {/*        <Header inverted={theme === 'dark'}  as='h3' floated='left'>Видеоруководство</Header>*/}
            {/*    </Grid.Column>*/}
            {/*</Grid.Row>*/}

            {/*<Grid.Row>*/}
            {/*    <Grid.Column>*/}
            {/*        <Segment inverted={theme === 'dark'} textAlign='left' >*/}

            {/*        </Segment>*/}

            {/*    </Grid.Column>*/}
            {/*</Grid.Row>*/}
        </Grid>
    );
}

export default SettingsPage;
