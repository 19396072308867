import React, {useEffect, useState} from 'react';
import {Sidebar, Header, Icon} from 'semantic-ui-react';
import OrdersAccordion from "./OrdersAccordion";
import {useSelector} from "react-redux";
import OrdersLeftSideBarItems from "./OrdersLeftSideBarItems";

const VISIBILITY_SETTINGS = [
    'visibleReservationGoods',
    'visibleCurrency',
    'visibleSaleschannel',
    'visibleProject',
    'visibleEmployee',
    'visibleGroup',
    'visibleOrdersNumbering',
    'visibleClientAvgRating',
    'visibleClientNotes',
    'visibleDeliveryOptionName',
    'visibleDeliveryOptionShippingService',
    'visiblePaymentOptionName',
    'visibleSource',
    'visibleCpaCommissionAmount',
    'visibleDontCallCustomerBack',
    'visiblePsPromotionName',
    'visibleHasOrderPromoFreeDelivery',
    'visiblePaymentDataStatus',
    'visibleStore',
];


const SECTIONS = [
    {
        title: "Автоматизация",
        settings: [
            { setting: 'visibleReservationGoods', text: 'Резервирование товаров' }
        ]
    },
    {
        title: "Системная информация",
        settings: [
            { setting: 'visibleCurrency', text: 'Валюта' },
            { setting: 'visibleSaleschannel', text: 'Канал продаж' },
            { setting: 'visibleProject', text: 'Проект' },
            { setting: 'visibleEmployee', text: 'Сотрудник' },
            { setting: 'visibleGroup', text: 'Отдел' }
        ]
    },
    {
        title: "Дополнительная информация",
        settings: [
            { setting: 'visibleOrdersNumbering', text: 'Нумирация заказов' },
            { setting: 'visibleClientAvgRating', text: 'Рейтинг клиента' },
            { setting: 'visibleClientNotes', text: 'Комментарий пользователя' },
            { setting: 'visibleDeliveryOptionName', text: 'Название способа доставки' },
            { setting: 'visibleDeliveryOptionShippingService', text: 'Стоимость доставки' },
            { setting: 'visiblePaymentOptionName', text: 'Название способа оплаты' },
            { setting: 'visibleSource', text: 'Источник заказа' },
            { setting: 'visibleCpaCommissionAmount', text: 'Комиссия за заказ' },
            { setting: 'visibleDontCallCustomerBack', text: 'Клиент просит не звонить' },
            { setting: 'visiblePsPromotionName', text: 'Данные об акциях по доставке' },
            { setting: 'visibleHasOrderPromoFreeDelivery', text: 'Заказ с бесплатной доставкой' },
            { setting: 'visiblePaymentDataStatus', text: 'Способ оплаты' }
        ]
    }
];

const useVisibilitySettings = (settingOrders) => {
    const [visibility, setVisibility] = useState(VISIBILITY_SETTINGS.reduce((acc, key) => ({ ...acc, [key]: true }), {}));

    useEffect(() => {
        if (!settingOrders) return;
        setVisibility(prevVisibility => ({...prevVisibility, ...settingOrders.visibleOrderSyncItems}));
    }, [settingOrders]);

    return [visibility, setVisibility];
};

const OrdersLeftSideBar = ({settingOrders = null,
                               setOrderArrayData = () => {},
                               stateOrderArray}) => {

    const [visible, setVisible] = useState(false);
    const theme = useSelector(state => state.app.theme);
    const themeClass = theme === 'dark' ? 'mainSideBar theme-dark-segment' : 'mainSideBar theme-light-left-panel';
    const [visibility, setVisibility] = useVisibilitySettings(settingOrders);


    const onSettingsClick = () => {
        setVisible(!visible);
    }

    const handleVisibilityChange = key => () => {
        setVisibility(prevVisibility => ({...prevVisibility, [key]: !prevVisibility[key]}));
    };



    return (

        <Sidebar.Pushable>

            <Sidebar
                direction='right'
                animation='overlay'
                onHide={() => setVisible(false)}
                visible={visible}
                width='wide'
                className='orderLeftSideBar'
            >
                <div className={themeClass}
                    style={{padding: '10px',
                             height: '100%',
                }}>

                    <Header inverted={theme === 'dark'} as='h2' textAlign='left' style={{marginTop: '10px'}}>
                        Настройки
                        <Icon inverted={theme === 'dark'}
                            name='arrow right'
                            style={{float: 'right',
                                   cursor: 'pointer',
                                   fontSize: '1.0em'
                                  }}

                            onClick={() => setVisible(false)}
                        />
                    </Header>


                    {SECTIONS.map((section, index) => (
                        <React.Fragment key={index}>
                            <Header inverted={theme === 'dark'} as='h4' textAlign='left' style={{marginTop: '10px', marginBottom: '10px'}}>
                                {section.title}
                            </Header>
                            {section.settings.map((setting) => (
                                <OrdersLeftSideBarItems
                                    key={setting.setting}
                                    text={setting.text}
                                    onCheckChange={handleVisibilityChange(setting.setting)}
                                    checked={visibility[setting.setting]}
                                />
                            ))}
                        </React.Fragment>
                    ))}

                </div>
            </Sidebar>

            <Sidebar.Pusher dimmed={visible} style={{minHeight: '100vh'}}>
                <OrdersAccordion
                    stateOrderArray={stateOrderArray}
                    visibleOrderSyncItems={visibility}
                    settingOrders={settingOrders}
                    setOrderArrayData={setOrderArrayData}
                    onSettingsClick={onSettingsClick}
                ></OrdersAccordion>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

export default OrdersLeftSideBar;