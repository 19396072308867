import React, {Fragment, useEffect, useMemo} from 'react';
import {Divider, Grid, Checkbox, Icon} from 'semantic-ui-react';
import {useSelector} from "react-redux";
import DropdownMoySklad from "../../formControls/DropdownMoySklad";
import {Link} from "react-router-dom";

const typeArticle = [
    {
        id: 'code',
        name: 'Код товара (рекомендовано)'
    },
    {
        id: 'article',
        name: 'Артикул товара'
    },
    {
        id: 'externalCode',
        name: 'Внешний код товара'
    }
]

const typeStock = [
    {
        id: 'quantity',
        name: 'Доступно'
    },
    {
        id: 'freeStock',
        name: 'Остаток с учетом резерва'
    },
    {
        id: 'stock',
        name: 'Остаток без учета резерва'
    }
]

const images_urls_count = Array.from({length: 10}, (_, index) => ({
    id: String(index + 1),
    name: String(index + 1)
}))


const UpdateInfoProduct = ({
                               stateOrderArray,
                               productAttributes,
                               stateExportProducts,
                               setStateExportProductsData = () => {},
                           }) => {
    const theme = useSelector(state => state.app.theme);


    useEffect(() => {
        if (!stateExportProducts.quantity_in_stock)
            setStateExportProductsData('delete_zero_stock', false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateExportProducts.quantity_in_stock, stateExportProducts.delete_zero_stock])

    const rows = [
        {
            text: 'Артикул/Код товара',
            check: 'sku',
            select1: {
                fields: typeArticle,
                notChosen: false,
                activeOptions: 'sku_field',
            }
        },
        {
            text: 'Наименование товара',
            check: 'name',
        },
        {
            text: 'Описание товара',
            check: 'description',
        },
        {
            text: 'Цена',
            check: 'price',
            select1: {
                fields: stateOrderArray.priceTypeArray,
                notChosen: false,
                activeOptions: 'price_field',
            }
        },
        {
            text: 'Оптовая цена',
            check: 'price_wholesale',
            select1: {
                fields: stateOrderArray.priceTypeArray,
                notChosen: false,
                activeOptions: 'price_wholesale_field',
            }
        },
        {
            text: 'Минимальный заказ для оптовой цены',
            check: 'min_order_for_price_wholesale',
            show: stateExportProducts.price_wholesale,
            select1: {
                fields: productAttributes,
                type: ['long'],
                notChosen: false,
                activeOptions: 'min_order_for_price_wholesale_field',
                p_down: 'Тип поля Число целое'
            }
        },
        {
            text: 'Фото',
            check: 'images_urls',
            select1: {
                fields: images_urls_count,
                notChosen: false,
                activeOptions: 'images_urls_count',
            }
        },

        {
            text: 'Предзаказ',
            check: 'presence',
            select1: {
                fields: productAttributes,
                notChosen: false,
                activeOptions: 'presence_field',
                type: ['long'],
                p_down: 'Тип поля Число целое'
            }
        },
        {
            text: 'Количество',
            check: 'quantity_in_stock',
            select1: {
                p_up: 'Тип остатка',
                fields: typeStock,
                notChosen: false,
                activeOptions: 'quantity_in_stock_field',
            },
            select2: {
                p_up: 'Со склада (ов)',
                fields: stateOrderArray.storeArray,
                notChosen: false,
                multiSelect: true,
                activeOptions: 'quantity_in_stock_stores_field',
                placeholder: 'Все склады'
            },
        },
        // {
        //     text: 'Удалять товар с нулевым остатком',
        //     check: 'delete_zero_stock',
        //     disabled: !stateExportProducts.quantity_in_stock
        // },
        // {
        //     text: 'Группа',
        //     check: 'group',
        // },
        {
            text: 'Поисковые запросы',
            check: 'keywords',
            select1: {
                fields: productAttributes,
                notChosen: false,
                activeOptions: 'keywords_field',
                type: ['string', 'text'],
                p_down: 'Тип поля текст, строка'
            }
        },
        {
            text: 'Товар со скидкой/после скидки',
            check: 'discount',
            select1: {
                fields: stateOrderArray.priceTypeArray,
                notChosen: false,
                activeOptions: 'discount_field',
                p_down: 'Тип поля текст, строка'
            }
        },
        {
            text: 'Личные заметки',
            check: 'labels',
            select1: {
                fields: productAttributes,
                notChosen: false,
                activeOptions: 'labels_field',
                type: ['string', 'text'],
                p_down: 'Тип поля текст, строка'
            }
        },
        {
            text: 'Код маркировки',
            check: 'gtin',
        },
        {
            text: 'Номер устройства',
            check: 'mpn',
            select1: {
                fields: productAttributes,
                notChosen: false,
                activeOptions: 'mpn_field',
                type: ['string', 'text'],
                p_down: 'Тип поля текст, строка'
            }
        },
        {
            text: 'Производитель',
            check: 'manufacturer',
            select1: {
                fields: productAttributes,
                notChosen: false,
                activeOptions: 'manufacturer_field',
                type: ['string', 'text'],
                p_down: 'Тип поля текст, строка'
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ];


    const clickAddAttributes = () => {
        const newAttributesField = stateExportProducts.attributes_field;
        newAttributesField.push({text: '', value: null})
        setStateExportProductsData('attributes_field', newAttributesField)
    }

    const clickDeleteAttributes = (index) => {
        if (stateExportProducts.attributes_field.length <= 1) return;
        const newAttributesField = stateExportProducts.attributes_field.filter((item, i) => i !== index);
        setStateExportProductsData('attributes_field', newAttributesField)
    }


    return (
        <Grid textAlign='left' style={{marginTop: '5px'}} verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={4} className='grid-column-1'>
                    <p className='over-text'>Satu.kz</p>
                </Grid.Column>
                <Grid.Column width={2}>

                </Grid.Column>
                <Grid.Column width={2} className='grid-column-1'>
                    <p className='over-text'>МойСклад</p>
                </Grid.Column>
                <Grid.Column width={2} className='grid-column-2'>

                </Grid.Column>
            </Grid.Row>

            {rows.map((item, index) => {
                if (!item.hasOwnProperty('show') || item.show) {
                    return (<Fragment key={item.check}>
                        <Grid.Row>
                            <Grid.Column width={4} className='grid-column-1'>
                                <strong>{item.text}</strong>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Checkbox className={theme}
                                          toggle
                                          disabled={item.disabled ?? false}
                                          checked={Boolean(stateExportProducts[item.check])}
                                          onChange={() => setStateExportProductsData(item.check, !Boolean(stateExportProducts[item.check]))}
                                > </Checkbox>
                            </Grid.Column>
                            <Grid.Column width={2} className='grid-column-2'>
                                {item.hasOwnProperty('select1') &&
                                    (
                                        <>
                                            {item.select1.hasOwnProperty('p_up') &&
                                                <p className="sub-text">{item.select1.p_up}</p>}
                                            <DropdownMoySklad
                                                fields={item.select1.fields}
                                                notChosen={item.select1?.notChosen}
                                                type={item.select1?.type || null}
                                                multiSelect={item.select1?.multiSelect || false}
                                                activeOptions={stateExportProducts[item.select1.activeOptions]}
                                                onOptionsChange={value => {
                                                    setStateExportProductsData(item.select1.activeOptions, value)
                                                }}
                                            ></DropdownMoySklad>
                                            {item.select1.hasOwnProperty('p_down') &&
                                                <p className="sub-text">{item.select1.p_down}</p>}
                                        </>
                                    )
                                }


                            </Grid.Column>
                            <Grid.Column width={2} className='grid-column-2'>
                                {item.hasOwnProperty('select2') &&
                                    (
                                        <>
                                            {item.select2.hasOwnProperty('p_up') &&
                                                <p className="sub-text">{item.select2.p_up}</p>}
                                            <DropdownMoySklad
                                                placeholder={item.select2.placeholder ?? 'Выберите поле'}
                                                fields={item.select2.fields}
                                                notChosen={item.select2?.notChosen || false}
                                                type={item.select2?.type || null}
                                                multiSelect={item.select2?.multiSelect || false}
                                                activeOptions={stateExportProducts[item.select2.activeOptions]}
                                                onOptionsChange={value => {
                                                    setStateExportProductsData(item.select2.activeOptions, value)
                                                }}
                                            ></DropdownMoySklad>
                                            {item.select2.hasOwnProperty('p_down') &&
                                                <p className="sub-text">{item.select2.p_down}</p>}
                                        </>
                                    )
                                }
                            </Grid.Column>
                        </Grid.Row>

                        <Divider/>
                    </Fragment>)
                }
            })}


            <Grid.Row>
                <Grid.Column width={4} className='grid-column-1'>
                    <strong>Характеристики</strong>
                </Grid.Column>
                <Grid.Column width={2}>
                    <Checkbox
                        className={theme}
                        toggle
                        checked={Boolean(stateExportProducts.attributes)}
                        onChange={() => setStateExportProductsData('attributes', !Boolean(stateExportProducts.attributes))}
                    > </Checkbox>
                </Grid.Column>
                <Grid.Column width={5} className='grid-column-1'>

                    {stateExportProducts.attributes_field && stateExportProducts.attributes_field.map((item, index) => (
                        <Fragment key={index}>
                            <div style={{display: "flex", alignItems: "center", maxWidth: '406px'}}>
                                <DropdownMoySklad style={{}}
                                                  fields={productAttributes}
                                                  notChosen={false}
                                                  type={['string', 'text']}
                                                  activeOptions={item.value}
                                                  onOptionsChange={value => {
                                                      const newAttributesField = stateExportProducts.attributes_field.map((c, i) => {
                                                          if (i === index) {
                                                              const text = productAttributes.find(option => option.id === value);
                                                              return {text: text.name, value}
                                                          }
                                                          return c;
                                                      })
                                                      setStateExportProductsData('attributes_field', newAttributesField)
                                                  }}
                                ></DropdownMoySklad>
                                <Icon name="delete" size='large' className='icon-delete'
                                      onClick={() => clickDeleteAttributes(index)}/>
                            </div>
                            <p className="sub-text">Тип поля текст, строка</p>
                        </Fragment>
                    ))}
                    <Link style={{marginRight: '10px'}} className='link'
                          onClick={clickAddAttributes}
                    >
                        Добавить характеристику
                    </Link>

                </Grid.Column>
            </Grid.Row>

        </Grid>
    );
};

export default UpdateInfoProduct;
