import {Button, Checkbox, Grid, Header, Input, Segment} from 'semantic-ui-react'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setThemeLight, setThemeNight} from "../../../redux/appActions";
import {pathSettings} from "../../../utils/Requests";
import {toast} from "react-toastify";
import PhoneNumber from "../../formControls/PhoneNumber";

function SettingsPage({settingMain = null, setSideBarAccountsData = () => {}, loadSettings = () => {}}) {
    const theme = useSelector(state => state.app.theme);
    const accountId = useSelector(state => state.app.accountId);
    const activeSettingsId = useSelector(state => state.app.activeSettingsId);
    const dispatch = useDispatch();
    const themeClass = theme === 'dark' ? 'theme-dark-segment' : 'theme-light-segment';
    const [token, setToken] = useState( '');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('' );

    const switchTheme = function () {
        if (theme === 'dark') {
            dispatch(setThemeLight());
        } else {
            dispatch(setThemeNight());
        }
    }

    const showToast = (message, type) => {
        toast[type](message, {
            theme,
            autoClose: 1000,
            position: "top-center",
        });
    }

    const onSaveClick = () => {

        if (!token) {
            showToast("Введите правильный токен", 'error');
            return;
        }

        if (phoneNumber.length < 11 || phoneNumber.length > 12) {
            showToast("Введите правильный номер телефона", 'error');
            return;
        }

        const data = {
            main: {
                token,
                name,
                phoneNumber
            }
        }

        const ok = () => {
            console.log("Сохраняем главные настройки");
            loadSettings();
            showToast("Сохранено!", 'success');
        }



        pathSettings(data, activeSettingsId, accountId)
            .then(() => {
                ok();
                setSideBarAccountsData(data.main);
            })
            .catch((error) => showToast(error.message, 'error'));
    }

    useEffect(() => {
        if (settingMain) {
            setToken(settingMain.token ? settingMain.token : '');
            setName(settingMain.name ? settingMain.name: '');
            setPhoneNumber(settingMain.phoneNumber ? settingMain.phoneNumber: '');

        }
    }, [settingMain])


    return (
        <Grid className={themeClass} >
            <Grid.Row>
                <Grid.Column width={11}>
                    <Header inverted={theme === 'dark'}  as='h3' floated='left'>Настройка Satu</Header>
                </Grid.Column>
                <Grid.Column floated='right' width={3}>
                    <Button inverted={theme === 'dark'} floated='right' primary content='Сохранить' onClick={onSaveClick}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment inverted={theme === 'dark'} textAlign='left' >
                        <Header  as='h3'>Токен</Header>
                        <p style={{fontSize: 'small'}}>Укажите ваш токен Satu для дальнейшей работы с приложением.</p>
                        <Input style={{width: '100%', minWidth: 200, maxWidth: 400}} value={token} onChange={(_, {value}) => {setToken(value)}} placeholder='Введите ваш токен...'/>
                        <Header  as='h3'>Название Компании</Header>
                        <Input style={{width: '100%', minWidth: 200, maxWidth: 400}} value={name}  onChange={(_, {value}) => {setName(value)}} placeholder='Введите название Компании...'/>
                        <Header  as='h3'>Номер телефона</Header>
                        <PhoneNumber phone={phoneNumber} onPhoneNumberChange={setPhoneNumber}></PhoneNumber>
                        <Header  as='h3'>Темная тема</Header>
                        <Checkbox className={theme} toggle onClick={switchTheme} checked={theme === 'dark'}></Checkbox>
                    </Segment>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default SettingsPage;
