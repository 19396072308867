import {Accordion, Button, Checkbox, Grid, Header, Icon, Segment} from 'semantic-ui-react';
import {useDispatch, useSelector} from "react-redux";
import OrdersStatuses from "./OrdersAccordionContent/OrdersStatuses";
import OrdersSync from "./OrdersAccordionContent/OrdersSync";
import {setOrdersAccordionActiveIndex} from "../../redux/appActions";
import {useCallback, useEffect, useState} from "react";
import OrdersDocuments from "./OrdersAccordionContent/OrdersDocuments";
import {getMoySkladData, pathSettings} from "../../utils/Requests";
import OrdersRequiredAttributesForm from "./OrdersAccordionContent/OrdersRequiredAttributesForm";
import {showErrorToast, showSuccessToast} from "../../utils/utils";

const OrdersAccordion = ({settingOrders = null,
                             visibleOrderSyncItems,
                             stateOrderArray,
                             setOrderArrayData = () => {},
                             onSettingsClick = () => {}}) => {
    const activeIndex = useSelector(state => state.app.ordersAccordionActiveIndex || []);
    const theme = useSelector(state => state.app.theme);
    const activeSettingsId = useSelector(state => state.app.activeSettingsId);
    const accountId = useSelector(state => state.app.accountId);
    const dispatch = useDispatch();
    const themeClass = theme === 'dark' ? 'theme-dark-segment' : 'theme-light-segment';
    const [dataDocuments, setDataDocuments] = useState({})
    const [dataStatuses, setDataStatuses] = useState({})
    const [dataCustomerOrderRequiredAttributes, setDataCustomerOrderRequiredAttributes] = useState([])
    const [isSync, setIsSync] = useState(false);



    const [stateOrder, setStateOrder] = useState({
        organization: null,
        autoCounterparty: true,
        counterparty: null,
        typeArticle: 'externalCode',
        type_article_satu: 'external_id',
        stateOrder: null,
        priceType: null,
        reservationGoods: true,
        saleschannel: null,
        store: null,
        autoOrdersNumbering: true,
        ordersNumbering: null,
        clientNotes: null,
        currency: null,
        project: null,
        employee: null,
        group: null,
        clientAvgRating: null,
        deliveryOptionName: null,
        deliveryOptionShippingService: null,
        paymentOptionName: null,
        source: null,
        cpaCommissionAmount: null,
        dontCallCustomerBack: null,
        psPromotionName: null,
        hasOrderPromoFreeDelivery: null,
        paymentDataStatus: null,
    });



    const setStateOrderData = (key, data) => {
        setStateOrder(prevState => ({...prevState, [key]: data}));
    }


    useEffect(() => {
        if (!settingOrders?.dataOrderSync) return;
        const dataOrderSync = settingOrders.dataOrderSync;
        setStateOrder(prevState => ({
            ...prevState,
            ...dataOrderSync
        }));

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingOrders])



    useEffect(() => {
        if (!stateOrder.autoCounterparty && stateOrderArray.counterpartyArray.length === 0) {

            getMoySkladData('counterparty', accountId, data => setOrderArrayData('counterpartyArray', data))
                .then(() => console.log("counterpartyArray обновлен"))
                .catch(() => console.log("counterpartyArray ошибка обновления"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateOrder.autoCounterparty])


    useEffect(() => {
        if (!settingOrders) return;
        setIsSync(settingOrders.is_sync)
    }, [settingOrders])

    const handleClick = useCallback((e, titleProps) => {
        const {index} = titleProps;
        const newIndex = activeIndex.includes(index)
            ? activeIndex.filter(i => i !== index)
            : [...activeIndex, index];
        dispatch(setOrdersAccordionActiveIndex(newIndex));
    }, [activeIndex, dispatch]);

    const isVisibleControlOrNull = useCallback((visibleControl, control) => {
        return (visibleOrderSyncItems?.[visibleControl] ? control : null)
    }, [visibleOrderSyncItems]);

    const onSave = () => {
        console.log("Сохраняем данные заказов");
        const dataOrderSync = {
            ...stateOrder,
            counterparty: (!stateOrder.autoCounterparty ? stateOrder.counterparty : null),
            reservationGoods: (visibleOrderSyncItems?.visibleReservationGoods ? stateOrder.reservationGoods : false),
            saleschannel: isVisibleControlOrNull('visibleSaleschannel', stateOrder.saleschannel),
            autoOrdersNumbering: (visibleOrderSyncItems?.visibleOrdersNumbering ? stateOrder.autoOrdersNumbering : true),
            ordersNumbering: (!stateOrder.autoOrdersNumbering && visibleOrderSyncItems?.visibleOrdersNumbering ? stateOrder.ordersNumbering : null),
            clientNotes: isVisibleControlOrNull('visibleClientNotes', stateOrder.clientNotes),
            currency: isVisibleControlOrNull('visibleCurrency', stateOrder.currency),
            project: isVisibleControlOrNull('visibleProject', stateOrder.project),
            employee: isVisibleControlOrNull('visibleEmployee', stateOrder.employee),
            group: isVisibleControlOrNull('visibleGroup', stateOrder.group),
            clientAvgRating: isVisibleControlOrNull('visibleClientAvgRating', stateOrder.clientAvgRating),
            deliveryOptionName: isVisibleControlOrNull('visibleDeliveryOptionName', stateOrder.deliveryOptionName),
            deliveryOptionShippingService: isVisibleControlOrNull('visibleDeliveryOptionShippingService', stateOrder.deliveryOptionShippingService),
            paymentOptionName: isVisibleControlOrNull('visiblePaymentOptionName', stateOrder.paymentOptionName),
            source: isVisibleControlOrNull('visibleSource', stateOrder.source),
            cpaCommissionAmount: isVisibleControlOrNull('visibleCpaCommissionAmount', stateOrder.cpaCommissionAmount),
            dontCallCustomerBack: isVisibleControlOrNull('visibleDontCallCustomerBack', stateOrder.dontCallCustomerBack),
            psPromotionName: isVisibleControlOrNull('visiblePsPromotionName', stateOrder.psPromotionName),
            hasOrderPromoFreeDelivery: isVisibleControlOrNull('visibleHasOrderPromoFreeDelivery', stateOrder.hasOrderPromoFreeDelivery),
            paymentDataStatus: isVisibleControlOrNull('visiblePaymentDataStatus', stateOrder.paymentDataStatus)
         }

            const data = {
                orders: {
                    ordersSettings: {
                        ...dataOrderSync, ...visibleOrderSyncItems, ...dataDocuments
                    },
                    dataStatuses,
                    dataCustomerOrderRequiredAttributes
                }
            }
            pathSettings(data, activeSettingsId, accountId)
            .then(() => showSuccessToast("Сохранено", theme))
                .catch(error => showErrorToast(error.message, theme));

        }

        const onSync = () => {
            const _isSync = !isSync;
            setIsSync(_isSync);
            const data = {
                orders: {
                    ordersSettings: {
                        is_sync: _isSync,
                    },
                    dataCustomerOrderRequiredAttributes
                }

            }
            pathSettings(data, activeSettingsId, accountId)
                .then(() => showSuccessToast("Сохранено", theme))
                .catch(error => showErrorToast(error, theme));
        }


        return (
            <div className={themeClass}>
                <Segment basic className={themeClass}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Accordion>
                                    <Accordion.Title
                                        active={activeIndex.includes(0)} // Проверка, содержится ли индекс панели в массиве активных индексов
                                        index={0}
                                        onClick={handleClick}
                                        style={{display: 'flex', justifyContent: 'space-between'}}
                                    >
                                        <Header inverted={theme === 'dark'} as='h3'>
                                            <Icon name='dropdown'/>
                                            Синхронизация заказов
                                        </Header>

                                        <Grid onClick={(e) => e.stopPropagation()}
                                              style={{flexGrow: 1, paddingLeft: '15px', marginTop: '-21px'}}>
                                            <Grid.Row verticalAlign='middle'>
                                                <Grid.Column width={2} textAlign='left'>
                                                    <Checkbox className={theme} toggle checked={isSync} onChange={onSync}/>
                                                </Grid.Column>
                                                <Grid.Column width={14} textAlign='right'>
                                                    <Button inverted={theme === 'dark'} primary content='Сохранить'
                                                            onClick={onSave} floated='right'/>
                                                    <Button inverted={theme === 'dark'} icon labelPosition='left'
                                                            floated='right' onClick={() => onSettingsClick()}>
                                                        <Icon name='settings'/>
                                                        Настройки
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Accordion.Title>

                                    <Accordion.Content active={activeIndex.includes(0)}>
                                        <Segment inverted={theme === 'dark'}>
                                            <OrdersSync
                                                stateOrderArray={stateOrderArray}
                                                stateOrder={stateOrder}
                                                setStateOrderData={setStateOrderData}
                                                visibleOrderSyncItems={visibleOrderSyncItems}></OrdersSync>
                                        </Segment>
                                    </Accordion.Content>

                                    <Accordion.Title
                                        active={activeIndex.includes(1)}
                                        index={1}
                                        onClick={handleClick}
                                    >
                                        <Header inverted={theme === 'dark'} as='h3'>
                                            <Icon name='dropdown'/>
                                            Документы
                                        </Header>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex.includes(1)}>
                                        <Segment inverted={theme === 'dark'}>
                                            <OrdersDocuments dataDocuments={settingOrders?.dataDocuments}
                                                             onChangeItems={(data) => setDataDocuments({...dataDocuments, ...data})}
                                                             statuses={stateOrderArray?.statesArray}></OrdersDocuments>
                                        </Segment>
                                    </Accordion.Content>

                                    <Accordion.Title active={activeIndex.includes(2)}
                                                     index={2}
                                                     onClick={handleClick}
                                    >
                                        <Header inverted={theme === 'dark'} as='h3'>
                                            <Icon name='dropdown'/>
                                            Статусы
                                        </Header>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex.includes(2)}>
                                        <Segment inverted={theme === 'dark'}>
                                            <OrdersStatuses dataStatuses={settingOrders?.dataStatuses}
                                                            onChangeItems={(data) => setDataStatuses(data)}
                                                            statuses={stateOrderArray?.statesArray}></OrdersStatuses>
                                        </Segment>
                                    </Accordion.Content>


                                    <Accordion.Title
                                        active={activeIndex.includes(3)}
                                        index={3}
                                        onClick={handleClick}
                                    >
                                        <Header inverted={theme === 'dark'} as='h3'>
                                            <Icon name='dropdown'/>
                                            Обязательные поля
                                        </Header>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex.includes(3)}>
                                        <Segment inverted={theme === 'dark'}>
                                            <OrdersRequiredAttributesForm
                                                onChangeItems={(values) => setDataCustomerOrderRequiredAttributes(values)}
                                                attributes={stateOrderArray?.customerOrderRequiredAttributes}></OrdersRequiredAttributesForm>
                                        </Segment>
                                    </Accordion.Content>


                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        );
    };

    export default OrdersAccordion;