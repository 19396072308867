import 'react-toastify/dist/ReactToastify.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './App.scss'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setAccountId} from "./redux/appActions";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import {getUserContext} from "./utils/Requests";
import SideBarAccounts from "./components/SideBarAccounts";


function App() {
    const dispatch = useDispatch();
    const [height, setHeight] = useState(0);
    const theme = useSelector(state => state.app.theme);


    useEffect(() => {
        let win = window.parent;
        let scrollHeight = 8000; //document.documentElement.scrollHeight-20;
        if (scrollHeight !== height) {
            setHeight(scrollHeight);
            const sendObject = {
                height: scrollHeight,
            };
            win.postMessage(sendObject, '*');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof process.env.REACT_APP_TOKEN !== 'undefined' && process.env.REACT_APP_TOKEN !== '') {
            dispatch(setAccountId(process.env.REACT_APP_TOKEN));
            return;
        }

        console.log("getUserContext");
        const query = new URLSearchParams(window.location.search);
        const contextKey = query.get('contextKey');
        if (!contextKey) return;
        console.log(`contextKey = ${contextKey}`);

        getUserContext(contextKey).then(accountId => {
            dispatch(setAccountId(accountId));
        }).catch(error => {
            toast.error(error.message, {
                theme,
                autoClose: false,
                position: "top-center",
            });
        });
        // eslint-disable-next-line
    }, []);


    return (
        <Router>
            <div className="App" style={{minHeight: '100vh', overflow: 'hidden'}}>
                <Routes>
                    <Route path="/" element={<SideBarAccounts/>}/>
                </Routes>
            </div>
            <ToastContainer/>
        </Router>
    );
}

export default App;
