import {Menu, Sidebar, Header} from 'semantic-ui-react'
import {useState} from "react";
import {useSelector} from "react-redux";
import SettingsPage from "./homeMenuComponents/SettingsPage";
import ContactPage from "./homeMenuComponents/ContactPage";
import LogPage from "./homeMenuComponents/LogPage";

function HomeMenu({settingMain, setSideBarAccountsData = () => {}, loadSettings = () =>{} }) {
    const theme = useSelector(state => state.app.theme);
    const [activeItem, setActiveItem] = useState('HomeSettings');
    const handleItemClick = (name) => setActiveItem(name);

    return (<Sidebar.Pushable
            style={{minHeight: '100vh'}}>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted={theme === 'dark'}
                vertical
                visible={true}
                className='homeSideBar'
            >
                <Menu.Item as={'div'}>
                    <Header inverted={theme === 'dark'} as='h3' >Главная</Header>
                </Menu.Item>

                <Menu.Item
                    as={'a'}
                    name='HomeSettings'
                    active={activeItem === 'HomeSettings'}
                    onClick={() => handleItemClick('HomeSettings')}
                >
                    Настройки
                </Menu.Item>

                <Menu.Item style={{height: '71px', display: 'flex', alignItems: 'center' }}
                    as={'a'}
                    name='HomeErrorLogs'
                    active={activeItem === 'HomeErrorLogs'}
                    onClick={() => handleItemClick('HomeErrorLogs')}
                >
                    Журнал ошибок
                </Menu.Item>
                <Menu.Item
                    as={'a'}
                    name='HomeContacts'
                    active={activeItem === 'HomeContacts'}
                    onClick={() => handleItemClick('HomeContacts')}
                >
                    Контакты

                </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher>
                <div style={{marginLeft: '200px', padding: '10px'}}>
                    {activeItem === 'HomeSettings' && <SettingsPage setSideBarAccountsData={setSideBarAccountsData}  settingMain={settingMain} loadSettings={loadSettings}></SettingsPage>}
                    {activeItem === 'HomeErrorLogs' && <LogPage></LogPage>}
                    {activeItem === 'HomeContacts' && <ContactPage></ContactPage>}
                </div>
            </Sidebar.Pusher>
        </Sidebar.Pushable>);
}

export default HomeMenu;
