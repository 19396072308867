import React, {useMemo, useState} from 'react'
import {Button, Header, Segment, Table, TextArea} from 'semantic-ui-react'
import {useSelector} from "react-redux";
import {formatDate} from "../../../utils/utils";
import Modal from 'react-modal';

const statusMapping = {
    'SUCCESS': {text: 'Выполнено', color: 'green'},
    'PARTIAL': {text: 'Частично выполнено', color: 'gray'},
    'FATAL': {text: 'Ошибка', color: 'red'},
    'FAIL': {text: 'Ошибка', color: 'red'},
};



const ExportProductsReport = ({exportStatus}) => {
    const theme = useSelector(state => state.app.theme);
    const [isOpen, setIsOpen] = useState(false);
    const [textError, setTextError] = useState('');

    const customStyles = useMemo( () => ({
        content: {
            top: '324px',
            borderRadius: '30px',
            border: 0,
            background: theme === 'dark' ? 'black' : 'rgb(245, 245, 245)',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '80%',
            height: '500px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Установите здесь нужный цвет затемнения
        },
    }), [theme]);

    const openModal = (index) => {
        const text = (exportStatus[index].errors) ? JSON.stringify(exportStatus[index].errors, null, 4) : ''
        setTextError(text);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const errorCount = (index) => {
        const errors = exportStatus[index].errors;
        if (!Array.isArray(errors)) return '0';
        return (parseInt(exportStatus[index].with_errors_count) || 0) + (errors[0]?.errors?.length || 0);
    }

    return (<div>

        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Модальное окно"
            style={customStyles}
            appElement={document.getElementById('root')}
        >
            <Header inverted={theme === 'dark'} as={'h3'} >
                Ошибки
            </Header>
            <Segment
                style={{ width: '100%',
                    height: '90%',
                    margin: 0,
            }}
                inverted={theme === 'dark'}>
                <TextArea
                    value={textError}
                    className={theme === 'dark' ? 'textarea-black' : ''}
                />
            </Segment>
        </Modal>


        <Table
            inverted={theme === 'dark'}

        >

            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Дата и время</Table.HeaderCell>
                    <Table.HeaderCell>Код</Table.HeaderCell>
                    <Table.HeaderCell>Всего</Table.HeaderCell>
                    <Table.HeaderCell>Импортировано</Table.HeaderCell>
                    <Table.HeaderCell>Создано</Table.HeaderCell>
                    <Table.HeaderCell>Обновлено</Table.HeaderCell>
                    <Table.HeaderCell>Ошибок</Table.HeaderCell>
                    <Table.HeaderCell>Статус</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    Array.isArray(exportStatus) && exportStatus.map((item, index) => {
                        return (<Table.Row key={item.created_at}>
                            <Table.Cell>{formatDate(item.created_at).toLocaleString()}</Table.Cell>
                            <Table.Cell>{item.id_import}</Table.Cell>
                            <Table.Cell>{item.total}</Table.Cell>
                            <Table.Cell>{item.imported}</Table.Cell>
                            <Table.Cell>{item.created}</Table.Cell>
                            <Table.Cell>{item.updated}</Table.Cell>
                            <Table.Cell><Button className='link' onClick={() => openModal(index)}>{ errorCount(index)}</Button></Table.Cell>
                            <Table.Cell>
                                <p style={{color: statusMapping?.[item.status]?.color}}>{statusMapping?.[item.status]?.text}</p>
                            </Table.Cell>
                        </Table.Row>)
                    })
                }

            </Table.Body>
        </Table>
    </div>)
}
export default ExportProductsReport