import React, {useEffect, useState} from 'react'
import { Dropdown } from 'semantic-ui-react'

const DropdownMoySklad = ({ fields = [] ,
                            type = null,
                            activeOptions = null,
                            onOptionsChange = () => {},
                            multiSelect = false,
                            notChosen = true,
                            loading = false,
                            search = true,
                            selection = true,
                              placeholder = 'Выберите поле'}) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!Array.isArray(fields) || fields.length === 0) return;
        // Проверяем, является ли type массивом, и если нет, преобразуем его в массив
        const types = Array.isArray(type) ? type : [type];

        // Фильтруем поля по типу
        const newOptions = type
            ? fields
                .filter(field => types.includes(field.type))
                .map(field => ({
                    key: field.id,
                    text: field.name,
                    value: field.id
                }))
            : fields.map(field => ({
                key: field.id,
                text: field.name,
                value: field.id
            }));

        // Добавляем в начало массива опцию "Не выбрано"
        if (notChosen && !multiSelect) {
            newOptions.unshift({key: 'null', text: 'Не выбрано', value: null});
        }

        setOptions(newOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    useEffect(() => {
        if (!activeOptions && options.length > 0 && !multiSelect) {
            onOptionsChange(options[0].value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOptions, options]);

    return (
        <Dropdown
            placeholder={placeholder}
            fluid
            loading={loading}
            multiple={multiSelect}
            search={search}
            selection={selection}
            options={options}
            value={ multiSelect ? (Array.isArray(activeOptions) ? activeOptions : []) : activeOptions || options[0]?.value}
            onChange={(event, { value }) => {
                onOptionsChange(value);
            }}
        />
    )
}

export default DropdownMoySklad;